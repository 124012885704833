.container {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 500px;
  margin: 2rem;
}

.text-container {
  display: flex;
  min-width: 100%;
  height: 110px;
}

.btn-container {
  display: flex;
  min-width: 100%;
  justify-content: flex-end;
}
