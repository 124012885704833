.gridItem {
  border-radius: 5px;
  padding: 14px;
  height: 100%;
  min-height: 100%;
}
.gridItemLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  /* height: 10%; */
  min-height: 50px;
}

.gridItemChild {
  display: flex;
}

.legendContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-height: 350px;
  padding: 15px;
  margin-top: 26px;
  border-radius: 5px;
  background-color: rgb(244, 244, 244);
}

.legendBox {
  border: 1px solid gray;
}

.legendHeader {
  display: flex;
  align-items: center;
}

.legendLight {
  background-color: rgb(228, 228, 250);
}

.legendMed {
  background-color: rgb(111, 111, 195);
}

.legendDark {
  background-color: rgb(63, 63, 183);
}

.legendAltered {
  background-color: rgb(255, 149, 149);
}

.redText {
  color: red;
}

.border {
  border: 1px solid gray;
}
