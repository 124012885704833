.viewContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dataGridContainer {
  width: 100%;
  padding: 2% 4%;
}
