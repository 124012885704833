.user-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.user-page-title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  border-bottom: 10px solid #225782;
}

.user-table-contatiner {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 3rem 1rem;
  min-height: 25px;
}

.user-table-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
}

.add-user-btn {
  padding: 0.25rem 2rem;
  background-color: #225782;
  color: white;
  border-radius: 5px;
  border-color: black;
  font-weight: 500;
}

.user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.user-org-role-mult-select {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.user-fname-lname {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.user-email-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.role-select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.user-input-field {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.user-remove-checkbox {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* justify-content: center; */
}

.disable-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  margin-top: 1rem;
}

.disable-user {
  width: 50%;
  padding-left: 1rem;
}

.disable-checkbox {
  margin: 1rem 1.5rem;
  transform: scale(2);
}

.user-modal-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 1.5rem 0 0 0;
  max-height: 10vh;
}

.user-modal-btn {
  padding: 0.25rem 2.5rem;
  background-color: #153a59;
  color: white;
  border-radius: 5px;
  font-weight: 400;
  margin: 0 0.5rem;
}

.user-modal-btn:disabled {
  color: rgba(255, 255, 255, 0.5);
  background-color: #666;
}
