.--RFI-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.--RFI-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 2rem;
}

.--RFI-title {
  align-self: flex-start;
  font-size: 30px;
  margin-left: 10px;
}

.--RFI-tabs {
  display: inline-flex;
  width: 100%;
  background-color: #0e2d57;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

.--RFI-tab-button {
  min-width: 320px;
  margin: 10px 1rem;
  padding: 1px;
  border: none;
  border-radius: 5px;
  background-color: #0e2d57;
  color: white;
  font-size: larger;
  text-transform: uppercase;
}

.--RFI-table-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 0 1rem;
}

.--RFI-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 4rem;
}

.center-header {
  justify-content: center;
}

.next-prev-page-arrows {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-arrow {
  margin: 0.5rem;
  border-radius: 5px;
}

.table-row-display {
  margin: 0 0.5rem 0 0;
}

.page-arrow:hover {
  background-color: gold;
}

.page-arrow:disabled {
  /* background-color: grey; */
}

.--RFI-button-container-and-status {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.--RFI-table-action-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.--RFI-table-action-button-container:disabled {
  background-color: gray;
}

.--RFI-table-action-button-container-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.--RFI-table-action-button-container-bottom:disabled {
  background-color: gray;
}

.--RFI-status {
  text-decoration: underline;
  text-align: end;
  color: #0e2d57;
  margin-right: 20px;
  margin-top: 10px;
  font-style: italic;
  font-weight: bold;
  font-size: large;
}

.--RFI-table-action-button {
  padding: 0.5rem 1rem;
  margin: 0.25rem 1rem 0.25rem 0;
  background-color: #225782;
  min-width: 175px;
  font-size: medium;
  color: white;
  border: 0 solid black;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.--RFI-csv-grid-toolbar-container {
  min-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0.5rem 1rem;
  cursor: pointer;
}

.--RFI-csv-grid-toolbar-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}

.--RFI-csv-export-link {
  background-color: #225782;
  color: white;
  border: 0 solid black;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: center;
}

.--RFI-csv-export-link:disabled {
  color: rgba(255, 255, 255, 0.5);
  background-color: #666;
}

.--RFI-csv-clear-filter {
  background-color: #a04326;
  color: white;
  border: 0 solid black;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  width: 100px;
  text-align: center;
}

.--RFI-csv-clear-filter:disabled {
  background-color: gray;
}

#mui-4 {
  display: none;
}

.--RFI-csv-export-link:hover {
  color: white;
  text-decoration: none;
}

.--RFI-table-action-button:disabled {
  color: rgba(255, 255, 255, 0.5);
  background-color: #666;
}

.--RFI-grid-header {
  background-color: #1d486c;
  color: white;
  font-size: larger;
  border-bottom: 1px solid black;
}

.--RFI-SATFA-grid-header {
  /* background-color: #aaa;
  color: black;
  font-size: larger;
  border-bottom: 1px solid black; */
}

.--RFI-SATFA-blank-row {
  background-color: #222;
  font-size: 0;
}

.--RFI-SATFA-disabled-cell {
  background-color: #aaa;
  display: none;
}

.--FTE-validation-disabled-cell {
  background-color: #888;
  display: none;
}

.editable-cell {
  background-color: rgb(245, 233, 165);
  color: black;
}

.disabled-editable-cell {
  /* background-color: #ccc; */
  background-color: #ecf4ff;
  color: black;
}

.--RFI-grid-warning-header {
  background-color: darkred;
  color: white;
  font-size: larger;
  border-bottom: 1px solid black;
  border-left: 2px solid black;
}

.--RFI-table-rows {
  border-bottom: 1px solid black;
}

.--RFI-warnings-errors-row {
  background-color: lightcoral;
}

.--RFI-warnings-errors-row:hover {
  background-color: lightcoral;
}

.--RFI-warnings-row {
  background-color: lightgoldenrodyellow;
}

.--RFI-warnings-row:hover {
  background-color: lightgoldenrodyellow;
}

.--RFI-warning-errors-cell {
  border-left: 2px solid grey;
}

.--RFI-warning-errors-cell:hover {
  border-left: 2px solid grey;
}

.--RFI-disclaimer-link {
  margin-left: 1rem;
}

.--RFI-tooltip-link {
  text-decoration: underline;
  color: red;
  font-size: larger;
  border: none;
  background-color: white;
}

.--RFI-disclaimer-modal-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: auto;
  top: 12.5%;
  left: 25%;
  width: 50%;
  height: 75%;
  box-shadow: 20;
  border: 2px solid black;
  background-color: #eee;
  padding: 0;
}

.--RFI-disclaimer-modal-header {
  width: 100%;
  background-color: #0e2d57;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.5rem 1rem;
}

.--RFI-disclaimer-modal-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 2rem;
}

.--RFI-disclaimer-message {
  text-align: left;
  margin-bottom: 0.5rem;
}

.--RFI-close-RFI-modal-btn {
  border-radius: 5px;
  background-color: #0e2d57;
  color: white;
  min-width: 100px;
  width: 50%;
  margin-top: 4rem;
  align-self: center;
}

.--RFI-warning-errors-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.--RFI-SATFA-hr-top {
  background-color: #0e2d57;
  height: 0.5rem;
  width: 100%;
  margin: 2rem 0;
}

.--RFI-SATFA-hr {
  background-color: #0e2d57;
  width: 100%;
  margin: 6rem 0;
}

.circular-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mhwl-header {
  color: white;
  background-color: #225782;
}
