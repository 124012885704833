.poc-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eee;
}

.poc-modal-header {
  display: inline-flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: #225782;
  color: white;
  width: 100%;
  padding: 0.5rem 1rem;
}

.poc-modal-header button {
  border: none;
  background-color: #225782;
}

.poc-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.fname-lname,
.email-phone,
.poc-role {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0 1rem;
  margin-top: 1rem;
}

.poc-role .input-field {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1rem;
  margin-right: 1rem;
}

.fname-lname .input-field {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
}

.email-phone .input-field {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1rem;
}

.poc-modal-button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  margin-top: 2rem;
}

.disable-checkbox {
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
}

.checkbox-input {
  margin: 0 0.75rem;
  width: 25px;
  height: 25px;
}

.checkbox-label {
  font-weight: 500;
}

.create-cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}

.poc-modal-btn {
  padding: 0.25rem 2.5rem;
  background-color: #153a59;
  color: white;
  border-radius: 5px;
  font-weight: 400;
  margin: 0 0.5rem;
}

.poc-modal-btn:disabled {
  background-color: #555;
}

.poc-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #555;
  padding: 0.2rem;
  margin-top: 1px;
  font-size: medium;
  letter-spacing: 1px;
  background-color: white;
}

.poc-input:disabled {
  background-color: #d6d6d6;
}

.asterick {
  color: red;
}
