@media screen and (max-width: 1100px) {
  .titleTest {
    font-size: 10px !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .topnav {
    width: 100%;
    flex-direction: column;
  }

  .topNavBox {
    width: 2000px !important;
    justify-content: center !important;
    margin-bottom: 10px;
  }
}
