.satfa-special-case-container {
    width: 100%;
}

.field-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    justify-content: center;
    margin-top: 2rem;
}

.satfa-special-case-enter-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    min-width: 310px;
    justify-content: space-between;
    margin: 2rem 0;
}

.satfa-label {
    font-weight: bold;
    font-size: larger;
    margin-right: 1rem;
}

.satfa-input {
    width: 4rem;
    border-radius: 5px;
}