.org-display-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 33%;
}

.peopmContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0px 1%;
}

.peopmActions {
  display: flex;
  justify-content: space-between;
  padding: 0.5% 0px;
  max-height: 100px;
  /* min-width: 1400px; */
  max-width: 100vw;
}

.rfiStatus {
  align-self: flex-end;
}

.peopmTABtns {
  display: flex;
  justify-content: flex-end;
  max-height: 40px;
  max-width: 50vw;
}

.peopmBtn {
  display: flex;
  flex-wrap: nowrap !important;
  margin-left: 5px !important;
}

.rfiInfoCont {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
