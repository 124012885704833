.landing-main {
    scrollbar-width: none;
}

.select-function-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
}

.select-function-grid-item {
    background-color: #1d71ca; 
    color: white;
    border-radius: 10px;
    padding: 1rem 2rem;
    border: none;
}

.logo-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 15px;
    padding-top: 20px;
}

.logo-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-item img {
    max-width: 100%;
    max-height: 100%;
}

.timeline-grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 0;
    row-gap: 15px; /* Adjust the gap value to your preference */
}

.timeline-bar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
    align-self: center;
    justify-self: center;
    background-color: gray;
}

.timeline-label-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.logo-item-new {
    position: relative;
    display: inline-block;
    margin: 10px 50px;
    padding: 5px;
    border-radius: 8px;
    text-align: center;
    width: 200px;
    height: 100px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 10;
  }

.logo-item-new img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

  .logo-item-new:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .logo-image {
    width: 100%;
    height: 100%;
  }
  .logo-image:hover {
    /* border: 2px solid #ddd; */
    filter: brightness(1.2);
    transform: scale(1.05);
  }
  
  .hover-box {
    position: absolute;
    top: 100%;  
    left: 20%;
    transform: translate(-50%, 0) scaleY(0);  
    transform-origin: top center;  
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(7px); 
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    opacity: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;  
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
    max-height: 0;
    z-index: 10; 
  }
  
  .logo-item-new:hover .hover-box {
    opacity: 1;
    max-height: 1000px;
    transform: translate(-50%, 0) scaleY(1);  
    animation: expandBox 0.3s ease forwards;
    padding-left: 20px;
    padding-right: 20px;
  }
/* 
  .logo-item-new:hover .org-text {
    visibility: hidden;
  } */
  
  @keyframes expandBox {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  
  
  .hover-box img {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;  
    flex-shrink: 0;
    transition: filter 0.3s ease, transform 0.3s ease;
  }

  .hover-box img:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
  }

  .two-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;
    width: 100%;
    left: 0%;
  }

  .message-board-container {
    padding-top: 50px;
  }

  .org-text {
    font-weight: 200;
    font-size: xx-large;
    padding-top: 3px;
  }

  .logo-item-new:hover .org-text {
    visibility: hidden;
  }
