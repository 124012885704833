.message-board {
    background-color: #2c3e50;
    border-radius: 8px;
    padding: 20px;
    max-width: 900px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border: 2px solid #34495e;
    font-family: 'Roboto', sans-serif;
  }

  .message-item {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #34495e;
    border-radius: 5px;
    border-left: 5px solid #033f1c;
  }
  
  .message-item:last-child {
    margin-bottom: 0;
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid #033f1c;
  }
  
  .message-content {
    flex-grow: 1;
  }
  
  .message-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bolder;
  }
  
  .user-name {
    font-weight: bold;
    font-size: 16px;
    color: #ecf0f1;
  }
  
  .timestamp {
    font-size: 12px;
    color: #bdc3c7;
  }
  
  .message-content p {
    margin: 0;
    font-size: 14px;
    color: #ecf0f1;
    line-height: 1.5;
  }
  
  