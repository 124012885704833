@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

.appContainer {
  display: flex !important;
  flex-direction: column !important;
  min-height: 100vh;
  max-width: 100vw;
}

.border2 {
  border: 1px solid blue;
}

.fullHeight {
  /* height: calc(100vh - 50px) !important; */
}

.notFullWidth {
  width: 100%;
  transition: width 5s ease-in-out !important;
  transition-delay: 10s;
}

.fullWidth {
  max-width: calc(100vw - 300px) !important;
  transition: width 5s ease-in-out !important;
  /* background-color: rgba(0, 0, 0, 0.05); */
}

.flex {
  display: flex;
}

.selectLabel {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.navbar-sides {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: 91px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 2rem;
}

.navbar-button-container {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  overflow-x: hidden;
  z-index: 2;
}

.navbar-button {
  padding: 0.5rem;
  background-color: #333;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.navbar-button:hover:enabled {
  background-color: white;
  color: #333;
}

.navbar-button-dropdown {
  color: white;
  background-color: #333;
}

.RFI-dropdown-select {
  color: white;
  background-color: #333;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.RFI-dropdown-select-btn {
  color: white;
  background-color: #333;
  border: none;
  width: 100%;
  text-transform: uppercase;
}

.RFI-dropdown-select-title {
  color: white;
  background-color: #333;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-decoration: underline;
}

.RFI-dropdown-select-btn:hover {
  background-color: white;
  color: #333;
}

.cui-top {
  /* position: fixed; */
  /* top: 0; */
  color: white;
  text-align: center;
  width: 100%;
  z-index: 3;
  /* height: 20px; */
}

.cui-bottom {
  position: fixed;
  bottom: 0;
  color: white;
  text-align: center;
  width: 100%;
  z-index: 3;
}

.odd-row {
  background-color: white;
}

.odd-row:hover {
  background-color: white;
}

.even-row {
  background-color: #ddd;
}

.even-row:hover {
  background-color: #ddd;
}
