.org-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.org-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.75rem 2rem;
  border-bottom: 10px solid #225782;
}

.fms-organizations {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.org-dropdown {
  display: flex;
  flex-direction: row;
  margin: 0 0.5rem;
}

.org-dropdown-select {
  margin-left: 1rem;
  padding-left: 0.5rem;
  border: 2px solid white;
  border-radius: 5px;
  background-color: #333;
  color: white;
  font-size: large;
  font-weight: bold;
  width: 200px;
}

.data-download {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 10%;
}

.data-download button {
  border: none;
  border-radius: 5px;
}

.data-download button:hover {
  background-color: #ddd;
}

.org-display {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 1rem;
}

.org-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.org-title-text {
  color: #225782;
}

.org-icon {
  margin-right: 1rem;
}

.org-table-contatiner {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1rem 0.5rem;
}

.table-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.assign-poc-btn {
  padding: 0.25rem 2rem;
  background-color: #225782;
  color: white;
  border-radius: 5px;
  border-color: black;
  font-weight: 500;
}

.assign-poc-btn:hover,
.poc-modal-btn:hover {
  background-color: black;
  color: white;
}

/* Modify css in MUI DataGrid */
.--grid-header {
  background-color: #225782;
  color: white;
  font-size: larger;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon.css-ptiqhd-MuiSvgIcon-root {
  color: white;
}
/* End DataGrid CSS */

.edit-delete-buttons {
  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
}

.edit-delete-buttons button {
  border: none;
  border-radius: 5px;
}

.edit-delete-buttons button:hover {
  border: 1px solid black;
}
